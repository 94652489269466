
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
















































































.net-cpl__centered-block {
  background-color: $c-white-dark;
}
.net-cpl__table {
  position: relative;
}
