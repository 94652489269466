
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




































































































































.net-cpl-table {
  @extend %table-legacy;

  .table__layout {
    grid-template-rows: 15rem 1fr 5rem;
  }

  .table-content__header__block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: auto;
    padding-top: $spacing;
    padding-bottom: $spacing;

    h4 {
      margin-bottom: $spacing * 0.5;
    }
  }

  .table-content__header__picture {
    position: relative;
    display: block;
    width: 20rem;
    height: 10rem;

    img {
      @include image-fit;

      object-fit: contain;
    }
  }

  .table-content__data-outer {
    overflow: visible;

    @include mq(l) {
      padding-bottom: $spacing * 3;
    }
  }

  .price-box {
    background-color: none;
    box-shadow: none;

    ::v-deep {
      .price-box__price > small :last-child {
        display: none;
      }
    }
  }
}

.section-hero__price-box-outer {
  position: relative;
}

.section-hero__price-box__label {
  @include center-y;

  left: -1rem;
  transform: translate(-100%, -50%);
}

.is-label {
  position: absolute;
  width: 100%;
  background: unset !important;

  @include mq(xl) {
    transform: translateY(6rem);
  }

  ::v-deep {
    .table-content__data__value {
      background: unset !important;

      small {
        color: $black;
        font-size: 1.4rem;
        opacity: 1;
      }
    }
  }
}

.table-content__data__item {
  position: relative;
}
